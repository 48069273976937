import axios from 'axios';

const api =
  process.env.NODE_ENV === 'development'
    ? axios.create({
        baseURL: 'http://localhost:3333/api',
      })
    : axios.create({
        baseURL: 'https://agendamento-ms.cfo.org.br/api',
      });

export default api;
